import { getAddress } from '@ethersproject/address'
import { Token } from '@pancakeswap/sdk'
import { mainnetTokens } from 'config/constants/tokens'

const getTokenLogoURL = (token?: Token) => {
  if (token && getAddress(token.address) !== getAddress(mainnetTokens.rp1.address)) {
    return `https://assets-cdn.trustwallet.com/blockchains/smartchain/assets/${token.address}/logo.png`
  }
  return `/images/tokens/${token.address}.png`
}

export default getTokenLogoURL
